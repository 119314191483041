import { FunctionComponent, useEffect, useState } from "react";
import "./index.scss";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
// import { DetailContent } from "../components/detail-content/DetailContent";
import { useLocation } from "react-router-dom";
import IntroCoursePortal from ".";
import { routesConfig } from "../../../../config/routes";
import PageHeader from "../../../../components/page-header/PageHeader";
import { getDetailCurriculum } from "../../../../service/portal-administration";

const { Content } = Layout;
const imgPath = "/images/";

const SettingIntroPage: FunctionComponent = () => {
  const location = useLocation();
  const [tab, setTab] = useState("1");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [dataUpdate, setDataUpdate] = useState<any>();
  const params = useParams();
  const currentUrl = window.location.href;
  const segments = currentUrl.split("/");
  const idParam = segments[segments.length - 1];

  const detailCurriculum = async (id: string) => {
    await getDetailCurriculum(id).then((res) => {
      setDataUpdate(res.data.data);
    });
  };

  useEffect(() => {
    detailCurriculum(idParam);
  }, []);

  const listBreadcrumb = [
    {
      title: (
        <a href={`/${routesConfig.portalAdministration}`}>
          Quản trị cổng thông tin
        </a>
      ),
    },
    {
      title: dataUpdate?.name || "Trang giới thiệu khóa học",
    },
  ];

  return (
    <>
      <Content className="detail-portal-administration">
        <PageHeader
          title={ dataUpdate?.name || "Trang giới thiệu khóa học"}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={tab === "4"}
        />
        <div className="tabs-heading-intro">
          <IntroCoursePortal />
        </div>
      </Content>
    </>
  );
};

export default SettingIntroPage;
