import { Form, Layout, message, Tabs, TabsProps } from "antd";
import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import FilterApprovalPlan from "./components/filter-approval-plan/FilterApprovalPlan";
import TableWaitApprovalPlan from "./components/table-approval-plan/TableWaitApprovalPlan";
import TableApprovalPlan from "./components/table-approval-plan/TableApprovalPlan";
import { useAuthStore } from "../../../stores/stores";
import {
  CourseApprovalType,
  FilterDateCoursePlan,
} from "../../../types/course-plan-approval";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { FilterDateSubmitSurvey } from "../../../types/survey-browse";
import { postCoursePlanSearchApprove } from "../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import ManyApprovalsSurvey from "./components/many-approvals/ManyApprovals";
import { ManyCoursePlanArroval } from "../../../types/course-plan-managment/mooc-approval";
import { useLocation } from "react-router-dom";
type ContentConstructionProps = {
  title?: string;
};
const ApprovalPlanList = (props: ContentConstructionProps) => {
  const { title } = props;
  const location = useLocation();
  const [isOpenManyApprovalsSurvey, setIsOpenManyApprovalsSurvey] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const userId = useAuthStore((state) => state.user?.id);
  const [activeTabKey, setActiveTabKey] = useState<string>(
    location?.state?.keyTab ?? "1"
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchValueNotFound, setSearchValueNotFound] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [listCourse, setListCourse] = useState<CourseApprovalType[]>([]);
  const [listCourseMany, setListCourseMany] = useState<ManyCoursePlanArroval[]>(
    []
  );
  const [listCourseApproval, setListCourseApproval] = useState<
    CourseApprovalType[]
  >([]);
  const [totalRecordsSubmit, setTotalRecordsSubmit] = useState<number>(0);
  const [totalRecordsApprove, setTotalRecordsApprove] = useState<number>(0);
  const [historyNavigation, setHistoryNavigation] = useState<Boolean>(false);

  const [form] = Form.useForm();
  const [filterData, setFilterData] = useState<FilterDateCoursePlan>({
    submissionDate: null,
    approvalDate: null,
  });
  const getData = (type: boolean) => {
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: !isEmpty(filterData.submissionDate?.[0])
        ? new Date(filterData.submissionDate?.[0]).toISOString()
        : "",
      submitedDateTo: !isEmpty(filterData.submissionDate?.[1])
        ? new Date(filterData.submissionDate?.[1]).toISOString()
        : "",
      approvalDateFrom: type
        ? !isEmpty(filterData.approvalDate?.[0])
          ? new Date(filterData.approvalDate?.[0]).toISOString()
          : ""
        : null,
      approvalDateTo: type
        ? !isEmpty(filterData.approvalDate?.[1])
          ? new Date(filterData.approvalDate?.[1]).toISOString()
          : ""
        : null,
      type: type,
      isPagination: true,
    };
    postCoursePlanSearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        if (type) {
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListCourseApproval(data.data);
        } else {
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListCourse(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getDataResetSearch = (type: boolean) => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: !isEmpty(filterData.submissionDate?.[0])
        ? new Date(filterData.submissionDate?.[0]).toISOString()
        : "",
      submitedDateTo: !isEmpty(filterData.submissionDate?.[1])
        ? new Date(filterData.submissionDate?.[1]).toISOString()
        : "",
      approvalDateFrom: type
        ? !isEmpty(filterData.approvalDate?.[0])
          ? new Date(filterData.approvalDate?.[0]).toISOString()
          : ""
        : null,
      approvalDateTo: type
        ? !isEmpty(filterData.approvalDate?.[1])
          ? new Date(filterData.approvalDate?.[1]).toISOString()
          : ""
        : null,
      type: type,
      isPagination: true,
    };
    postCoursePlanSearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        if (type) {
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListCourseApproval(data.data);
        } else {
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListCourse(data.data);
          setListCourseMany(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPageNumber(1);
    setPageSize(10);
    getDataResetSearch(false);
    getDataResetSearch(true);
  }, [activeTabKey]);

  useEffect(() => {
    // setSearchValue("");
    if (activeTabKey === "1") {
      getDataResetSearch(false);
    } else {
      getDataResetSearch(true);
    }
  }, [pageNumber, pageSize]);

  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">
          Không tìm kết quả với từ khoá "{searchValueNotFound}"
        </p>
      </span>
    ),
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
    cancelSort: "Huỷ sắp xếp",
  };
  const handleListCourse = () => {
    getData(true);
    getData(false);
  };
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <span>Chờ phê duyệt </span>
          <span className="border-total-record">{totalRecordsSubmit}</span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableWaitApprovalPlan
            locale={locale}
            totalRecords={totalRecordsSubmit}
            pageSize={pageSize}
            listCourse={listCourse}
            pageNumber={pageNumber}
            historyNavigation={historyNavigation}
            setHistoryNavigation={setHistoryNavigation}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            handleListCourse={handleListCourse}
            loading={loading}
            activeTabKey={activeTabKey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <span>Đã phê duyệt </span>
          <span className="border-total-record">{totalRecordsApprove}</span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableApprovalPlan
            locale={locale}
            totalRecords={totalRecordsApprove}
            pageSize={pageSize}
            historyNavigation={historyNavigation}
            setHistoryNavigation={setHistoryNavigation}
            listCourseApproval={listCourseApproval}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            loading={loading}
            activeTabKey={activeTabKey}
          />
        </div>
      ),
    },
  ];

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      submissionDate: null,
      approvalDate: null,
    });
    setLoading(true);
    if (activeTabKey === "1") {
      const data = {
        keyword: "",
        pageNumber: pageNumber,
        pageSize: pageSize,
        individualId: userId,
        submitedDateFrom: null,
        submitedDateTo: null,
        approvalDateFrom: null,
        approvalDateTo: null,
        type: false,
        isPagination: true,
      };
      postCoursePlanSearchApprove(data)
        .then((res) => {
          const { data } = res.data;
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListCourse(data.data);
          setListCourseMany(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      const data = {
        keyword: "",
        pageNumber: pageNumber,
        pageSize: pageSize,
        individualId: userId,
        submitedDateFrom: null,
        submitedDateTo: null,
        approvalDateFrom: null,
        approvalDateTo: null,
        type: true,
        isPagination: true,
      };
      postCoursePlanSearchApprove(data)
        .then((res) => {
          const { data } = res.data;
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListCourseApproval(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };
  const handleSubmitSearch = () => {
    setSearchValueNotFound(searchValue);
    setPageSize(10);
    setPageNumber(1);
    if (activeTabKey === "1") {
      getData(false);
    } else {
      getData(true);
    }
  };
  const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };
  const OpenManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(true);
  };
  const handleCloseManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(false);
  };
  const multipleApprove = () => {
    // console.log(listIdApprove);
  };
  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider>
        <div className="table-header--wrapper">
          <FilterApprovalPlan
            title={title}
            form={form}
            clearFilter={clearFilter}
            activeTabKey={activeTabKey}
            searchValue={searchValue}
            handleChangeInput={handleChangeInput}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            handleSubmitSearch={handleSubmitSearch}
            handleSearchEnter={handleSearchEnter}
            handleOpenManyApprovalsSurvey={OpenManyApprovalsSurvey}
            multipleApprove={multipleApprove}
          />
        </div>
        <div className="tabs-heading">
          <Tabs
            items={itemsTab}
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
          />
        </div>
        <ManyApprovalsSurvey
          listCourse={listCourseMany}
          open={isOpenManyApprovalsSurvey}
          handleCancel={() => {
            handleCloseManyApprovalsSurvey();
          }}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ApprovalPlanList;
