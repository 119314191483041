import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../../../../components/form-input/FormInput';
import './filter-table.scss';
import QuickModerateModal from '../../../quick-moderate-modal/QuickModerateModal';

type FilterTableProps = {
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  selectedRowKeys?: React.Key[];
  onFinish: (values: any) => void;
  hideSelection?: boolean;
  typeQuestion: boolean,
  setTypeQuestion: (value: boolean) => void,
}

const FilterTable: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  selectedRowKeys,
  onFinish,
  typeQuestion,
  setTypeQuestion,
  hideSelection = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="filter-table-ministry-moderation">
        <Row gutter={[8, 8]}>
          {!typeQuestion ? 
          <>
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 7 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 22 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </> : 
          <>
          <Col>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </>}
          
          {!typeQuestion ?
          <>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 22 }}>
            <div className="heading-resigter">
              <Button className="btn btn-primary" onClick={showModal}
              disabled={!hideSelection || selectedRowKeys?.length === 0}>
                <Typography.Text>Duyệt nhiều</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xs={{ span: 22 }}>
            <div className="heading-history">
              <Button className="btn btn-default" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
                <Typography.Text>Lịch sử kiểm duyệt</Typography.Text>
              </Button>
            </div>
          </Col>
          </> : ''}
        </Row>
        <QuickModerateModal 
          form={form}
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onFinishForm={onFinish}
          isMinistry={true}
        />
      </div>
    </>
  );
};

export default FilterTable;
