import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";
import {IBodyRequestDataPost} from "../../types/roleManagement";
import { Key } from "react";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getDetailQuestionBank: (id: any) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/get-detail-unit-bank/${id}`)
}

export const getDetailQuestionBankMinistry: (id: any) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/censor/${id}`)
}

export const putApproveQuestionBank: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigV2.put(`/question-bank/censor/approve`, param)
}

export const postCreateQuestionBank: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigV2.post(`/question-bank/create-new-quiz-bank`, param)
}

export const postCreateQuestionBankConnect: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigV2.post(`/question-bank/create-new-quiz-connect-bank`, param)
}

export const putUpdateQuestionBank: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigV2.put(`/question-bank/update-quiz-bank`, param)
}

export const putUpdateQuestionBankConnect: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigV2.put(`/question-bank/update-quiz-connect-bank`, param)
}

