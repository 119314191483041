import {axiosConfigV2} from "../../config/api/configApiv2";
import {AxiosResponse} from "axios";

export const getPageUnitBank: (payload?: any) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfigV2.post(`/question-bank/search-page-unit-bank`, payload);
};

export const createUnitBank: (payload: object) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/question-bank/create-new-unit-bank`, payload);
};

export const censorSendApprove: (payload: object) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`question-bank/censor/send-approve`, payload);
};

export const updateUnitBank: (payload: object) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.put(`/question-bank/update-unit-bank`, payload);
}

export const editUnitBank: (bankId: number) => Promise<AxiosResponse<any>> = (bankId) => {
  return axiosConfigV2.get(`/question-bank/get-edit-unit-bank/${bankId}`);
}
export const publishUnitBank: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/question-bank/censor/publish`,data);
}

export const getDataDetailUnitBank: (bankId: number) => Promise<AxiosResponse<any>> = (bankId) => {
  return axiosConfigV2.get(`/question-bank/get-detail-unit-bank/${bankId}`);
}

export const removeUnitBank: (bankId: number) => Promise<AxiosResponse<any>> = (bankId) => {
  return axiosConfigV2.delete(`/question-bank/delete-unit-bank/${bankId}`);
}

export const accessUnitBank: (dataReq: object) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.put(`/question-bank/update-access-unit-bank`, dataReq)
}

// get topic
export const getTopicFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-portfolio-value/find-by-portfolio`, data)
}

// get production unit
export const getAllProductionUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.get(`/question-bank/censor/get-all-production-unit`, data)
}

// get author
export const getAllAuthor: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.get(`/question-bank/censor/get-all-author`, data)
};

export const unitQuestionBankApprovedHistory: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-unit-question-bank-approved-history`, data);
};

export const quizQuestionBankApprovedHistory: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-question-bank-approved-history`, data);
};

// get list bank exam
export const getPageUnitBankPublic: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-page-unit-bank-public`, data)
}

export const getPageUnitBankInStorage: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-page-unit-bank-in-storage`, data);
};
