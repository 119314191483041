import { Button, Col, Form, Input, Layout, message, Row, Select } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import PageHeader from "../../../../page-header/PageHeader";
import "./create-question.scss";
import SafeLink from "../../../../link/SafeLink";
import { routesConfig } from "../../../../../config/routes";
import AddQuestionCourseModal from "../../../../modal/add-question-course";
import QuestionItem from "../../../../course-construction/question-item";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import { getAllValueByFilter } from "../../../../../service/category-management/portfolio-value-management";
import { getAccountUsers } from "../../../../../service/list-account-users/listAccountUsersApi";
import { get, isEmpty } from "lodash";
import { useAddLesson, useAuthStore } from "../../../../../stores/stores";
import { mapAnsFEtoBE } from "../../../../../utils/arrays";
import { ModeQuestion, QuestionTypeEnum } from "../../../../../constants";
import { HelpService } from "../../../../../service/helper.service";
import { convertQuillToString } from "../../../../../utils/convertQuillToString";
import {
  postCreateQuestionBank,
  postCreateQuestionBankConnect,
  putUpdateQuestionBank,
  putUpdateQuestionBankConnect,
} from "../../../../../service/question-bank";
import { useNavigate } from "react-router-dom";

const imgPath = "/images/";
const { Content } = Layout;
const { Option } = Select;
type CreateQuestionProps = {
  title: string;
};
const CreateQuestion = ({ title }: CreateQuestionProps) => {
  const navigate = useNavigate();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const question = useAddLesson((state: any) => state.question);
  const [form] = Form.useForm();
  const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
  const [courseBlockGroupIdClick, setCourseBlockGroupId] = useState<number>(0);
  const [selectedQuestionType, setSelectedQuestionType] = useState<
    string | null
  >(null);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const currentQuestionInfo = useAddLesson(
    (state) => state.currentQuestionInfo
  );
  const handleAddQuestion = () => {
    setAddQuestionModal(true);
  };
  const handleSave = () => {
    form.submit();
  };
  const { user } = useAuthStore();
  const listBreadcrumb = [
    {
      title: "Thư viện",
    },
    {
      title: "Kho đề cá nhân",
    },
    {
      title: (
        <SafeLink
          to={`/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listQuestionRepository}`}
        >
          <span>Danh sách câu hỏi</span>
        </SafeLink>
      ),
    },
    {
      title: "Thêm câu hỏi",
    },
  ];
  const helpService = new HelpService();
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataPermission: [],
    dataStatus: [],
    dataUsers: [],
  });

  const dataTags = [
    { code: "tag 1", name: "Tag 1" },
    { code: "tag 2", name: "Tag 2" },
  ];

  const fetchDataOptions = async () => {
    const dataReq = { page: 1, size: 1000 };
    try {
      const response = await Promise.allSettled([
        getAllValueByFilter(dataReq),
        getAccountUsers(dataReq),
      ]);

      // @ts-ignore
      setDataOptions({
        ...dataOptions,
        dataTopic:
          response[0].status === "fulfilled"
            ? response[0].value.data.data.moocPortfolioValueData.map(
                (value: { name: string; portfolio: number }) => ({
                  label: value.name,
                  code: value.portfolio,
                })
              )
            : [],
        dataUsers:
          response[1].status === "fulfilled"
            ? response[1].value.data.data.data
                .filter(
                  (i: { donViCongTac: any }) =>
                    i.donViCongTac === get(user, "donViCongTac", "")
                )
                .map((value: any) => ({
                  label: value.fullName,
                  code: value.id,
                }))
                .concat({
                  label: get(user, "username", ""),
                  code: get(user, "id", ""),
                })
            : [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const stripHtml = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  useEffect(() => {
    fetchDataOptions();
  }, []);
  const validateFormCreate = ["code", "authors", "title", "topics"];

  const createQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      let apiRespone: any;
      if (question.type === QuestionTypeEnum.CONNECT) {
        apiRespone = await postCreateQuestionBankConnect(dataReq);
      } else {
        apiRespone = await postCreateQuestionBank(dataReq);
      }
      const dataResponse = apiRespone.data;
      // setModeClick(QuestionProgressModeClick.END);
      // setCurrentIdQuestionAdd(dataRespone.body.id);
      // saveToIndexDB('current-course', { currentId: dataReq.id, id: dataReq.id, name: currentCourse.name, parent: currentParentCourse, });
      helpService.successMessage(dataResponse.message);
      navigate(
        `/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listQuestionRepository}`
      );
      // onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Tạo mới thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const updateQuiz = async (dataReq: any) => {
    try {
      setLoadingSubmit(true);
      if (question.type === QuestionTypeEnum.CONNECT) {
        await putUpdateQuestionBankConnect(dataReq);
      } else {
        await putUpdateQuestionBank(dataReq);
      }
      // setModeClick(QuestionProgressModeClick.END);
      helpService.successMessage("Cập nhật câu hỏi thành công");
      // saveToIndexDB('current-course', { currentId: dataReq.id, id: dataReq.id, name: currentCourse.name, parent: currentParentCourse, });
      // onRefreshQuizBlock();
    } catch (err: any) {
      helpService.errorMessage(err?.message || "Cập nhật thất bại");
      console.error(err);
    } finally {
      setTimeout(() => setLoadingSubmit(false), 1000);
    }
  };

  const handleSaveQuestion = () => {
    const value = form.getFieldsValue();
    let payload = {
      // id: question.id,
      // orderNumber: 1,
      // courseBlockGroupId: question.courseBlockGroupId,
      code: value.codeQuestion,
      level: value.level,
      authors: value.authors,
      tags: value.tags,
      topic: value.topic,
      type: question.type,
      question: currentQuestionInfo.questionName,
      questionValue: convertQuillToString(currentQuestionInfo.questionName),
      reminderText: currentQuestionInfo.reminderText || "",
      answers: mapAnsFEtoBE(currentQuestionInfo, question.type as number),
    };
    const strippedQuestionName = stripHtml(
      currentQuestionInfo.questionName
    ).trim();
    const checkArr = payload.answers.some((i: any) => isEmpty(i.filePath));
    if (
      (QuestionTypeEnum.CHOOSE_VIDEO === payload.type ||
        QuestionTypeEnum.CHOOSE_MULTI_VIDEO === payload.type ||
        QuestionTypeEnum.CHOOSE_IMAGE === payload.type ||
        QuestionTypeEnum.CHOOSE_MULTI_IMAGE === payload.type) &&
      checkArr &&
      payload.answers.length !== 0
    ) {
      message.error("Không được bỏ trống các giá trị file và nội dung");
    } else if (strippedQuestionName.length === 0) {
      message.error("Không được bỏ trống nội dung câu hỏi");
    } else {
      if (selectedQuestionType) {
        // setDirty(false);
        if (modeQuestion === ModeQuestion.CREATE) {
          createQuiz(payload);
        }
        if (modeQuestion === ModeQuestion.UPDATE) {
          // updateQuiz(payload);
        }
      }
      else{
        message.error('Không được bỏ trống nội dung câu hỏi')
      }
    }
  };

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "body-create-left") {
            handleSaveQuestion();
          }
        }}
      >
        <Content className="add-question">
          <PageHeader
            title={title}
            headerGroupButton={true}
            positionItem="column"
            listBreadcrumb={listBreadcrumb}
            breadcrumb={true}
            arrowLeft={imgPath + "arrowLeft.svg"}
            childrenGroupButton={
              <div className="button-container">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  loading={loadingSubmit}
                  onClick={handleSave}
                >
                  Lưu
                </Button>
              </div>
            }
          />
          <Row className="body-create-question">
            <AddQuestionCourseModal
              courseBlockGroupIdClick={courseBlockGroupIdClick}
              open={addQuestionModal}
              title={"Thêm câu hỏi"}
              closeIcon={true}
              okText={"Xác nhận"}
              cancelText={"Huỷ"}
              icon={null}
              closeModal={() => setAddQuestionModal(false)}
              onSelectQuestionType={(type: any) =>
                setSelectedQuestionType(type)
              }
            />
            <Col span={4} style={{ backgroundColor: "#FFF" }}>
              <Form
                form={form}
                name="body-create-left"
                className="body-create-left"
                layout="vertical"
              >
                <Form.Item
                  label="Mã câu hỏi"
                  name={"codeQuestion"}
                  rules={[
                    { required: true, message: "Vui lòng nhập mã câu hỏi!" },
                  ]}
                >
                  <Input placeholder="Nhập mã câu hỏi" />
                </Form.Item>

                <Form.Item
                  label="Độ khó"
                  name={"level"}
                  rules={[{ required: true, message: "Vui lòng chọn độ khó!" }]}
                >
                  <Select placeholder="Chọn độ khó">
                    <Option value={0}>Dễ</Option>
                    <Option value={1}>Trung bình</Option>
                    <Option value={2}>Khó</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="authors"
                  rules={[
                    { required: true, message: "Vui lòng chọn tác giả!" },
                  ]}
                  label="Tác giả"
                >
                  <FormItemDropdown
                    modeDropdown="multiple"
                    options={dataOptions.dataUsers}
                    allowClear={true}
                    placeholder="Chọn tác giả"
                  />
                </Form.Item>

                <Form.Item
                  name="topic"
                  label="Chủ đề"
                  rules={[{ required: true, message: "Vui lòng chọn chủ đề!" }]}
                >
                  <FormItemDropdown
                    // priority="code"
                    modeDropdown="multiple"
                    options={dataOptions.dataTopic}
                    allowClear={true}
                    placeholder="Chọn chủ đề"
                  />
                </Form.Item>

                <Form.Item name="tags" label="Nhãn">
                  <FormItemDropdown
                    options={dataTags}
                    modeDropdown={"tags"}
                    allowClear={true}
                    placeholder="Thêm nhãn"
                  />
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleAddQuestion}>
                    <PlusOutlined /> Chọn loại câu hỏi
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={16}
              style={{ backgroundColor: "#grey", marginLeft: "100px" }}
            >
              {selectedQuestionType && <QuestionItem />}
            </Col>
          </Row>
        </Content>
      </Form.Provider>
    </>
  );
};
export default CreateQuestion;
