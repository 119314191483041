import PageHeader from "../../../../components/page-header/PageHeader";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  FormInstance,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import FilterBankExam from "../../../../components/course-library/bank-exam/filterBankExam/FilterBankExam";
import "./bank-exam-list.scss";
import {
  CloseOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TableData from "../../../show-component/table-data/TableData";
import FilterForm from "../../../../components/course-library/bank-exam/filterForm/FilterForm";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import "./bank-exam-list.scss";
import { routesConfig } from "../../../../config/routes";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { getAllAuthor, getAllProductionUnit, getPageUnitBankPublic, getTopicFilter } from "../../../../service/library";

type BankExamListProps = {
  title: string;
};

const BankExamList = ({ title }: BankExamListProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [listDataExam, setListDataExam] = useState<any>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [dataOptions, setDataOptions] = useState<any>({
    dataTopic: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataUnit: [],
    dataAuthor: [],
  });

  const [filterData, setFilterData] = useState<any>({
    topics: null,
    typeProduction: null,
    productionUnitId: null,
    authors: null,
    dateFrom: null,
    dateTo: null,
  });

  const listBreadcrumb = [
    {
      title: "Thư viện",
    },
    {
      title: "Ngân hàng đề",
    },
    {
      title: "Danh sách ngân hàng đề",
    },
  ];

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Thêm vào khóa học",
      },
      {
        key: "1",
        label: "Lưu trữ",
      },
    ];
    return options1;
  }

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      ...filterData,
      topics: null,
      typeProduction: null,
      productionUnitId: null,
      authors: null,
      dateFrom: null,
      dateTo: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const onFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      topicIds: values.topic,
      typeApproved: values.type,
      typeProduction: values.level,
      userIds: values.author,
      dateFrom: values?.time
        ? (values?.time[0]).add(1, "day").toISOString()
        : null,
      dateTo: values?.time
        ? (values?.time[1]).add(1, "day").toISOString()
        : null,
    });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "courseName,asc"
        : sorter.order === "descend"
        ? "courseName,desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tiêu đề",
      dataIndex: "question",
      key: "question",
      width: 30,
    },
    {
      title: "Số câu hỏi",
      dataIndex: "totalQuiz",
      key: "totalQuiz",
      width: 20,
    },
    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
      width: 20,
    },
    {
      title: "Phân cấp",
      dataIndex: "typeProduction",
      key: "typeProduction",
      width: 20,
      // render(_: any, record) {
      //   return (
      //     <>
      //       {record.typeProduction === 1 ? (
      //         <>Cấp giảng viên</>
      //       ) : record.typeProduction === 2 ? (
      //         <>Cấp trường</>
      //       ) : (
      //         <>Cấp bộ</>
      //       )}
      //     </>
      //   );
      // },
    },
    {
      title: "Lượt dùng",
      dataIndex: "numberOfUse",
      key: "numberOfUse",
      width: 20,
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: "unit",
      key: "unit",
      width: 20,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updateDate",
      key: "updateDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {!isEmpty(record?.approvedSendDate)
              ? record?.approvedSendDate
              : null}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(
                    `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                    {
                      state: {
                        backUrl: `/${routesConfig.courseLibrary}/${routesConfig.bankExam}/${routesConfig.bankExamList}`,
                        mode: "0",
                        titleBreadCrumb: "Quản lý ngân hàng đề",
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({ key }) => {
                    if (key === "0") {
                      showModal();
                    }
                    if (key === "1") {
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const payload = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      topics: filterData.topics,
      // typeProduction: filterData.typeProduction,
      // productionUnitId: filterData.productionUnitId,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
      type: "unit_quiz",
      // sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ["approvedSendDate, desc"] : [`${fieldSortOrder},${statusOrder}`],
    };

    try {
      await getPageUnitBankPublic(payload).then((res) => {
        if (res.status === 200) {
          setListDataExam(res.data.data.content);
          setTotalRecords(res.data.data.totalElements);

          setTimeout(() => {
            setLoading(false);
          }, 333);
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // useEffect
  useEffect(() => {
    fetchData();
  }, [filterData, pageSize, pageNumber, statusOrder]);

  useEffect(() => {
    Promise.all([
      getTopicFilter({ code: "topic" }),
      getAllProductionUnit({name: null}),
      getAllAuthor({ name: null }),
    ]).then((res) => {
      const listTopic = res[0].data.data.moocPortfolioValueData;
      const listUnit = res[1].data.data.map((unit: any) => {
        return ({
          id: unit.uuid,
          name: unit.name,
        })
      });
      const listAuthor = res[2].data.data;

      setDataOptions({
        ...dataOptions,
        dataTopic: listTopic,
        dataUnit: listUnit,
        dataAuthor: listAuthor,
      });
    });
  }, []);

  return (
    <Layout className="page-header-group bank-exam-list">
      <Form.Provider onFormFinish={(name, { values, forms }) => {}}>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} breadcrumb={true}/>
            </div>
            <FilterBankExam
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FilterForm
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={onFinish}
            showFilter={showFilter}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataTable={listDataExam}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 800 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <Modal
          className="add-exam-course-modal"
          title="Thêm đề vào khóa học"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            icon: <PlusOutlined />,
          }}
          okText="Thêm"
          cancelButtonProps={{
            icon: <CloseOutlined />,
          }}
          cancelText="Hủy"
        >
          <Form layout={"vertical"}>
            <Form.Item
              name="course"
              label="Chọn khóa học"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn khóa học",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn khóa học"
              />
            </Form.Item>
            <Form.Item
              name="course"
              label="Chọn Chương"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn Chương",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn khóa học"
              />
            </Form.Item>
            <Form.Item
              name="course"
              label="Chọn Bài giảng"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn Bài giảng",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn Bài giảng"
              />
            </Form.Item>
          </Form>
        </Modal>
      </Form.Provider>
    </Layout>
  );
};

export default BankExamList;
