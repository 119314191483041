import { Button, Col, DatePicker, Form, FormInstance, Row, Typography } from "antd";
import { FunctionComponent } from "react";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { useForm } from "antd/es/form/Form";
import { get } from "lodash";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";

type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
  dataOptions?: any;
  typeQuestion?: boolean
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  showFilter,
  clearFilter,
  dataOptions,
  typeQuestion
}) => {
  const [form] = useForm();

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
            {!typeQuestion ? 
            <>
            <Col xl={{span: 5}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Phân cấp</Typography.Title>
              <Form.Item name="level">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn phân cấp" options={get(dataOptions, 'dataLevel')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Tác giả</Typography.Title>
              <Form.Item name="author">
                <FormItemDropdown modeDropdown="multiple" placeholder="Chọn tác giả" options={get(dataOptions, 'dataAuthor')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Phân loại</Typography.Title>
              <Form.Item name="type">
                <FormItemDropdown placeholder="Chọn phân loại" options={get(dataOptions, 'dataType')}/>
              </Form.Item>
            </Col>
            </> 
              : 
            <>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Phân cấp</Typography.Title>
              <Form.Item name="level">
                <FormItemDropdown placeholder="Chọn phân cấp" options={get(dataOptions, 'dataLevel')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Phân loại</Typography.Title>
              <Form.Item name="type">
                <FormItemDropdown placeholder="Chọn phân loại" options={get(dataOptions, 'dataType')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Đơn vị xuất bản</Typography.Title>
              <Form.Item name="unit">
                <FormItemDropdown placeholder="Chọn đơn vị xuất bản" options={get(dataOptions, 'dataUnit')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Kết quả duyệt</Typography.Title>
              <Form.Item name="approved">
                <FormItemDropdown placeholder="Chọn kết quả duyệt" options={get(dataOptions, 'dataApproved')}/>
              </Form.Item>
            </Col>
            </>}
            <Col xl={{span: 4}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="time">
                <DatePicker.RangePicker allowEmpty={[true, true]} placeholder={["Từ ngày", "Đến ngày"]} format='DD/MM/YYYY' />
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex align-end">
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined/>
                  <Typography.Text> Lọc </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter(form)}>
                  <DeleteOutlined/>
                  <Typography.Text> Xóa bộ lọc </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
