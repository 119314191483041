import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Radio, Typography, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";

type ModalProps = {
    form?: FormInstance<any>;
    isModalVisible: boolean;
    isMinistry: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    onFinishForm: (values: any) => void;
};

const QuickModerateModal: FunctionComponent<ModalProps> = ({
    form,
    isModalVisible,
    isMinistry,
    handleOk,
    handleCancel,
    onFinishForm
}) => {
  return (
    <>
      <Modal
        title="Duyệt nhanh"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div>
            {!isMinistry ?
            <Typography.Text>
              Nội dung sẽ được gửi lên cấp bộ sau khi duyệt
            </Typography.Text>
            :
            <></>}
            
            <div className="footer-modal-button">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
                icon={<CloseOutlined />}
              >
                Huỷ bỏ
              </Button>
              <Button
                key="ok"
                type="primary"
                onClick={handleOk}
                icon={<CheckOutlined />}
                style={{ marginLeft: "8px" }}
              >
                Xác nhận
              </Button>
            </div>
          </div>,
        ]}
        className="modal-fast-browsing"
      >
        <Form form={form} onFinish={onFinishForm}>
          <p>Nhập nội dung kiểm duyệt</p>
          <Form.Item name="status" initialValue={true}>
            <Radio.Group>
              <Radio value={true}>
                <Typography.Text>Duyệt</Typography.Text>
              </Radio>
              <Radio value={false}>
                <Typography.Text>Không duyệt</Typography.Text>
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="description">
            <TextArea
              placeholder="Nhập ý kiến"
              style={{
                marginLeft: "60px",
                width: "80%",
                height: "45px",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default QuickModerateModal;
