import { Card, Col, Empty, Row, Spin, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import StatisticalReportCard from "../../StatisticalReportCard";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import { get } from "lodash";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "./resource-chart-tab.scss";
import { getCourseByFormatUniversity, getCourseByIndustryGroup, getSynthesizeCourseUniversity, getUnitByIndustryGroup, getUniversityMultiMediaUnit, getUniversityReferenceSourceByType, getUniversityScormXapiUnit, getUniversityTestByType } from "../../../../../../service/statistical-report";
import { BarStackedChartProps, PieChart } from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  courseFormatUniversityList: BarStackedChartProps[];
  courseByIndustry: PieChart[];
  unitByIndustry: PieChart[];
  universityByTestType: PieChart[];
  universityReferenceSource: PieChart[];
  universityScormXapiUnit: PieChart[];
  universityMultiMediaUnit: PieChart[];
  synthesizeCourseUniversity: Array<any>;
  courseFormatUniversityTotal: number | null;
  courseByIndustryTotal: number | null;
  unitByIndustryTotal: number | null;
  universityByTestTypeTotal: number | null;
  universityReferenceSourceTotal: number | null;
  universityScormXapiUnitTotal: number | null;
  universityMultiMediaUnitTotal: number | null;
  synthesizeCourseUniversityTotal: number | null;
};


const ResourceChartTab = (props: Props) => {
  const {
    loading,
    courseFormatUniversityList,
    courseByIndustry,
    unitByIndustry,
    universityByTestType,
    universityReferenceSource,
    universityScormXapiUnit,
    universityMultiMediaUnit,
    synthesizeCourseUniversity,
    courseFormatUniversityTotal,
    courseByIndustryTotal,
    unitByIndustryTotal,
    universityByTestTypeTotal,
    universityReferenceSourceTotal,
    universityScormXapiUnitTotal,
    universityMultiMediaUnitTotal,
    synthesizeCourseUniversityTotal,
  } = props;

  const [dataStatistical, setDataStatistical] = useState({
    totalCourse: 0,
    rateCourse: 0,
    totalResource: 0,
    rateResource: 0,
    totalTest: 0,
    rateTest: 0,
    totalScormXAPI: 0,
    rateScormXAPI: 0,
    totalMultimedia: 0,
    rateMultimedia: 0,
    totalReferences: 0,
    rateReferences: 0,
  });

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  useEffect(() => {
    const course: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "Khóa học");
    const resource: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "Bài giảng");
    const test: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "Bài kiểm tra");
    const scormXAPI: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "SCORM & xAPI");
    const multimedia: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "Đa phương tiện");
    const references: any = synthesizeCourseUniversity.find((item: any) => item?.criteria === "Tài liệu tham khảo");

    setDataStatistical({
      totalCourse: course?.count,
      rateCourse: typeof course?.percentage === 'number' ? course.percentage.toFixed(2) : '0',
      totalResource: resource?.count,
      rateResource: typeof resource?.percentage === 'number' ? resource.percentage.toFixed(2) : '0',
      totalTest: test?.count,
      rateTest: typeof test?.percentage === 'number' ? test.percentage.toFixed(2) : '0',
      totalScormXAPI: scormXAPI?.count,
      rateScormXAPI: typeof scormXAPI?.percentage === 'number' ? scormXAPI.percentage.toFixed(2) : '0',
      totalMultimedia: multimedia?.count,
      rateMultimedia: typeof multimedia?.percentage === 'number' ? multimedia.percentage.toFixed(2) : '0',
      totalReferences: references?.count,
      rateReferences: typeof references?.percentage === 'number' ? references.percentage.toFixed(2) : '0',
    });
  }, [synthesizeCourseUniversity])

  const renderStatistical = () => {
    return (
      <>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Khóa học"
              value={get(dataStatistical, 'totalCourse', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateCourse = get(dataStatistical, 'rateCourse', 0);
                const isNegative = rateCourse < 0;
                const absRateCourse = Math.abs(rateCourse);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateCourse}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Bài giảng"
              value={get(dataStatistical, 'totalResource', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateResource = get(dataStatistical, 'rateResource', 0);
                const isNegative = rateResource < 0;
                const absRateResource = Math.abs(rateResource);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateResource}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Bài kiểm tra"
              value={get(dataStatistical, 'totalTest', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateTest = get(dataStatistical, 'rateTest', 0);
                const isNegative = rateTest < 0;
                const absRateTest = Math.abs(rateTest);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateTest}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="SCORM/x API"
              value={get(dataStatistical, 'totalScormXAPI', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateScormXAPI = get(dataStatistical, 'rateScormXAPI', 0);
                const isNegative = rateScormXAPI < 0;
                const absRateScormXAPI = Math.abs(rateScormXAPI);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateScormXAPI}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Đa phương tiện"
              value={get(dataStatistical, 'totalMultimedia', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateMultimedia = get(dataStatistical, 'rateMultimedia', 0);
                const isNegative = rateMultimedia < 0;
                const absRateMultimedia = Math.abs(rateMultimedia);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateMultimedia}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Tài liệu tham khảo"
              value={get(dataStatistical, 'totalReferences', 0)}
              precision={2}
              valueStyle={{ fontWeight: 500, fontSize: 30 }}
              formatter={(value) => {
                const rateReferences = get(dataStatistical, 'rateReferences', 0);
                const isNegative = rateReferences < 0;
                const absRateReferences = Math.abs(rateReferences);
                return (
                  <>
                  {value}
                  <span className="percentage-difference">
                    {absRateReferences}%
                    {isNegative ? (
                        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
                    ):(
                      <CaretUpOutlined style={{ color: "#52C41A" }} />
                    )}
                    </span>
                </>
                );   
              }}
            />
          </Card>
        </Col>
      </>
    )
  }

  return (
    <>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        {renderStatistical()}
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo hình thức xuất bản"
            content={
              <Spin spinning={loading.courseFormatUniversityList}>
                {courseFormatUniversityList.length > 0 ? (
                  <BarStackedChart
                  data={courseFormatUniversityList}
                  colors={["#FFC069", "#5CDBD3", "#69C0FF"]}
                  hasTotal={true}
                  totalData={courseFormatUniversityTotal ? courseFormatUniversityTotal : null}
                  ref={createChartRef(tab, "Số lượng khóa học theo hình thức xuất bản")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng khóa học theo hình thức xuất bản", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng khóa học theo hình thức xuất bản",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo khoa"
            content={
              <Spin spinning={loading.courseByIndustry}>
                {courseByIndustry.length > 0 ? (
                  <BarChart
                    data={courseByIndustry}
                    colors={["#69C0FF", "#FFC069"]}
                    showLegend={false}
                    hasTotal={true}
                    totalData={courseByIndustryTotal ? courseByIndustryTotal : null}
                    ref={createChartRef(tab, "Số lượng khóa học theo khoa")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng khóa học theo khoa", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng khóa học theo khoa",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading.unitByIndustry}>
                {unitByIndustry.length > 0 ? (
                  <BarChart
                    data={unitByIndustry}
                    colors={["#69C0FF", "#FFC069"]}
                    showLegend={false}
                    hasTotal={true}
                    totalData={unitByIndustryTotal ? unitByIndustryTotal : null}
                    ref={createChartRef(tab, "Số lượng bài giảng theo khoa")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng bài giảng theo khoa", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng bài giảng theo khoa",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra"
            content={
              <Spin spinning={loading.universityByTestType}>
                {universityByTestType.length > 0 ? (
                  <DonutChart
                    data={universityByTestType}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    ref={createChartRef(tab, "Số lượng bài kiểm tra")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng bài kiểm tra", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng bài kiểm tra",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo"
            content={
              <Spin spinning={loading.universityReferenceSource}>
                {universityReferenceSource.length > 0 ? (
                  <DonutChart
                    data={universityReferenceSource}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "Số lượng tài liệu tham khảo")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng tài liệu tham khảo", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng tài liệu tham khảo",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng SCORM & xAPI"
            content={
              <Spin spinning={loading.universityScormXapiUnit}>
                {universityScormXapiUnit.length > 0 ? (
                  <DonutChart
                    data={universityScormXapiUnit}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "Số lượng SCORM & xAPI")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng SCORM & xAPI", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng SCORM & xAPI",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện"
            content={
              <Spin spinning={loading.universityMultiMediaUnit}>
                {universityMultiMediaUnit.length > 0 ? (
                  <DonutChart
                    data={universityMultiMediaUnit}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "Số lượng học liệu đa phương tiện")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng học liệu đa phương tiện", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "Số lượng học liệu đa phương tiện",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ResourceChartTab;
