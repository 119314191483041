// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-exam .tabs-heading {
  margin-left: 20px; }
  .bank-exam .tabs-heading .tabs-heading-title {
    padding-top: 20px;
    padding-bottom: 20px; }
    .bank-exam .tabs-heading .tabs-heading-title .tabs-heading-title-text {
      font-weight: 550;
      font-size: 20px; }
  .bank-exam .tabs-heading .topic-list .topic-title {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400; }
  .bank-exam .tabs-heading .topic-list .topic-space-button {
    margin: 2px; }

.bank-exam .table-header--left .ant-breadcrumb {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/library/bank-exam/bankExam.scss"],"names":[],"mappings":"AAAA;EAEQ,iBAAiB,EAAA;EAFzB;IAKY,iBAAiB;IACjB,oBAAoB,EAAA;IANhC;MASgB,gBAAgB;MAChB,eAAe,EAAA;EAV/B;IAiBgB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,gBAAgB,EAAA;EApBhC;IAwBgB,WAAW,EAAA;;AAxB3B;EA+BY,WAAW,EAAA","sourcesContent":[".bank-exam {\n    .tabs-heading {\n        margin-left: 20px;\n\n        .tabs-heading-title {\n            padding-top: 20px;\n            padding-bottom: 20px;\n\n            .tabs-heading-title-text {\n                font-weight: 550;\n                font-size: 20px;\n            }\n\n        }\n\n        .topic-list {\n            .topic-title {\n                display: block;\n                margin-bottom: 10px;\n                font-size: 18px;\n                font-weight: 400;\n            }\n\n            .topic-space-button {\n                margin: 2px;\n            }\n        }\n    }\n\n    .table-header--left {\n        .ant-breadcrumb {\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
